// Locally, we use mock values for feature flags and do not hit flagsmith directly.
// This allows us to make our tests independent of flagsmith, and to test both branches
// without flipping flags for everyone else.
export const localFlagValues = {
  'flagsmith-initialized': {
    // See comment in SessionProvider for why this flag exists.
    enabled: true,
  },
  'billing-tab-enabled': {
    enabled: true,
  },
  'unit-integration-enabled': {
    enabled: true,
  },
  'fragment-inc-dashboard-pages': {
    enabled: true,
  },
  'stripe-integration-enabled': {
    enabled: true,
  },
  'ai-schema-generation-enabled': {
    enabled: true,
  },
  'smart-account-template-paths-enabled': {
    enabled: true,
  },
  'entry-group-recon-dashboard': {
    enabled: true,
  },
  'entry-versioning-enabled': {
    enabled: false,
  },
};

export type DashboardFlags = keyof typeof localFlagValues;
