/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */
import type { FC } from 'react';
import clsx from 'clsx';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import type { CurrencyCode } from '@fragment/types';

const MAX_FRACTIONAL_PARTS = 2;

export type AmountProps = {
  amount: string;
  currency: {
    code: CurrencyCode;
    customCode?: string | null;
    name: string;
    precision: number;
  };
  showCurrency?: boolean;
};

const formatZero = (precision: number) => {
  if (precision === 0) {
    return '0';
  }
  if (precision === 1) {
    return '0.0';
  }
  return '0.00';
};

type FormatAmountProps = Pick<AmountProps, 'amount'> & {
  isTruncated?: boolean;
  precision: AmountProps['currency']['precision'];
};
export const formatAmount = ({
  amount,
  precision,
  isTruncated = false,
}: FormatAmountProps) => {
  if (amount === '0') {
    // Return 0.00 when amount is 0, irrespective of precision.
    return formatZero(precision);
  }
  let preformattedAmount = amount;
  const isNegative = preformattedAmount.charAt(0) === '-';
  if (isNegative) {
    preformattedAmount = preformattedAmount.slice(1);
  }
  if (preformattedAmount.length < precision) {
    preformattedAmount = preformattedAmount.padStart(precision, '0');
  }
  const integerPart =
    preformattedAmount.slice(0, preformattedAmount.length - precision) || '0';
  // When integerPart is greater than Number.MAX_INTEGER, this will fail.
  const formattedIntegerPart = `${isNegative ? '-' : ''}${Intl.NumberFormat(
    'en-US'
  ).format(BigInt(integerPart))}`;
  // If this is a zero-precision currency, don't display a decimal point.
  if (precision === 0) {
    return formattedIntegerPart;
  }
  let fractionalPart = preformattedAmount.slice(
    preformattedAmount.length - precision
  );
  if (isTruncated && fractionalPart) {
    if (fractionalPart.length > MAX_FRACTIONAL_PARTS + 1) {
      fractionalPart = `${fractionalPart.slice(
        0,
        MAX_FRACTIONAL_PARTS
      )}&#8230;`;
    }
  }
  return `${formattedIntegerPart}.${fractionalPart}`;
};

export const Amount: FC<AmountProps> = ({
  amount,
  currency,
  showCurrency = true,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isTruncated, setIsTruncated] = useState<boolean>(true);
  const ref = useRef<HTMLSpanElement>(null);
  const onHover = useCallback(() => {
    setIsTruncated(false);
  }, []);
  const onLeave = useCallback(() => {
    setIsTruncated(true);
    // Clear the window selection only if this component set
    // the selection.
    if (ref.current !== null && isSelected) {
      window.getSelection()?.empty();
      setIsSelected(false);
    }
  }, [ref, isSelected]);
  const clickHandler = useCallback(() => {
    if (ref.current !== null) {
      window.getSelection()?.selectAllChildren(ref.current);
      setIsSelected(true);
    }
  }, [ref]);

  const formattedAmount = useMemo(
    () =>
      formatAmount({
        amount,
        precision: currency.precision,
        isTruncated,
      }),
    [amount, currency, isTruncated]
  );
  const suffix =
    currency.code === 'CUSTOM' ? currency.customCode : currency.code;
  return (
    <span>
      <span
        data-testid="amount"
        role="presentation"
        ref={ref}
        onClick={clickHandler}
        onMouseOver={onHover}
        onFocus={onHover}
        onMouseOut={onLeave}
        onBlur={onHover}
        className={clsx('flex', 'flex-row-reverse', 'inline-flex')}
        dangerouslySetInnerHTML={{
          __html: formattedAmount,
        }}
      />
      {showCurrency && ` ${suffix}`}
    </span>
  );
};
