import React from 'react';

export const Logo = () => (
  <span
    style={{
      fontFeatureSettings: `'ss01' on`,
      fontVariantLigatures: 'no-contextual',
    }}
  >
    <span className="text-fxl">FRAGMENT</span>
  </span>
);

export const InlineLogoStyle = {
  fontFeatureSettings: `'ss01' on`,
  fontVariantLigatures: 'no-contextual',
};

export const InlineLogo = () => (
  <span style={InlineLogoStyle}>
    <span>FRAGMENT</span>
  </span>
);
