import { useFlagsmith } from 'flagsmith/react';
import { sortBy } from 'lodash-unified';
import React, {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { getDefaultRoute } from 'utils/defaultRoute';
import { putLocalStorage } from 'utils/localStorage';
import { useLocation, useRoute } from 'wouter';
import { useAuth0 } from '@auth0/auth0-react';

import type { Workspace } from '../UserInfoProvider/UserInfoProvider';
import { useUserInfo } from '../UserInfoProvider/UserInfoProvider';

type ChangeWorkspaceFunction = (workspaceId: string) => void;

/**
 * An array representing workspaces the user has access to with the following elements:
 * * [0] Active Workspace (null if not found or unauthorized)
 * * [1] Function to change to a new workspace
 * * [2] List of workspaces currently accessible
 * * [3] Customer API token for the current workspace, possibly null
 */

export type WorkspaceContextValue = {
  workspace: Workspace | null;
  changeWorkspaceId: ChangeWorkspaceFunction;
  workspaces: Workspace[];
  buildNewWorkspaceUrl: (workspaceId: string) => string;
};

const defaultContext: WorkspaceContextValue = {
  workspace: null,
  changeWorkspaceId: () => {},
  workspaces: [],
  buildNewWorkspaceUrl: () => {
    throw new Error('Workspace Context Not Defined');
  },
};
export const WorkspaceContext =
  createContext<WorkspaceContextValue>(defaultContext);

/**
 * Only import this to mock a workspace in tests - otherwise, use the <WorkspaceProvider> component.
 */
export const WorkspaceProviderInternal = WorkspaceContext.Provider;

const defaultWorkspaceKey = 'default_workspace_id';

export const WorkspaceProvider = ({ children }: PropsWithChildren) => {
  const { user } = useAuth0();
  const { data } = useUserInfo();
  const [, params] = useRoute<{ workspaceId: string; rest: string }>(
    '/w/:workspaceId/:rest*'
  );
  const [, setLocation] = useLocation();

  const decodedWorkspaceId =
    params?.workspaceId && decodeURIComponent(params.workspaceId);
  const activeWorkspace = data?.workspaces?.find(
    (workspace) => workspace.id === decodedWorkspaceId
  );
  const activeWorkspaceId = activeWorkspace?.id;

  const buildNewWorkspaceUrl = useCallback(
    (workspaceId: string) =>
      // When changing workspaces, redirect the user back to the top-level subpage.
      // i.e: switching from /w/workspace-1/ledgers/<my-ledger>/childaccounts will navigate
      // to /w/workspace-2/ledgers/
      getDefaultRoute(workspaceId),
    []
  );

  const changeWorkspaceId: ChangeWorkspaceFunction = useCallback(
    (workspaceId: string) => {
      const newLocation = buildNewWorkspaceUrl(workspaceId);
      setTimeout(() => setLocation(newLocation), 0);
    },
    [buildNewWorkspaceUrl, setLocation]
  );

  useEffect(() => {
    if (activeWorkspaceId) {
      putLocalStorage(defaultWorkspaceKey, {
        id: activeWorkspaceId,
      });
    }
  }, [activeWorkspaceId]);

  const flagsmith = useFlagsmith();
  useEffect(() => {
    if (activeWorkspaceId) {
      flagsmith.setTrait('workspaceId', activeWorkspaceId);
    }
    if (activeWorkspace?.pod.id) {
      flagsmith.setTrait('podId', activeWorkspace.pod.id);
    }
    if (user?.email) {
      // Instead of sending flagsmith user email addresses, we're
      // sending them a boolean instead.
      const isInternalUser = user.email.endsWith('@fragment.dev');
      flagsmith.setTrait('internalUser', String(isInternalUser));

      const isCoveUser = user.email.endsWith('@cove.io');
      flagsmith.setTrait('coveUser', String(isCoveUser));
    }
  }, [activeWorkspaceId, activeWorkspace?.pod.id, user?.email, flagsmith]);

  const contextValue: WorkspaceContextValue = {
    workspace: activeWorkspace || null,
    changeWorkspaceId,
    buildNewWorkspaceUrl,
    workspaces: sortBy(data?.workspaces || [], (ws) => ws.name),
  };
  return (
    <WorkspaceProviderInternal value={contextValue}>
      {children}
    </WorkspaceProviderInternal>
  );
};

export const useWorkspace = () => useContext(WorkspaceContext);

export const useWorkspaceId = () => {
  const { workspace } = useWorkspace();
  if (!workspace?.id) {
    throw new Error('workspace not set');
  }
  return workspace.id;
};
