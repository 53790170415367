import clsx from 'clsx';
import React from 'react';
import { match } from 'ts-pattern';

import { type TextSizeType, getTextSize } from '../utils';

export type Props = Omit<JSX.IntrinsicElements['input'], 'ref' | 'size'> & {
  border?: boolean;
  size?: TextSizeType;
  flexContainer?: string; // It is nearly impossible to make a generic forwardRef component
  hasError?: boolean;
  label?: React.ReactNode;
  width?: string;
  padding?: string;
  background?: string;
  copyable?: boolean;
  align?: 'left' | 'right' | 'center';
  'data-testid'?: string;
  transparent?: boolean;
};

export const TextInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      size = 'fnormal',
      border = false,
      hasError = false,
      align = 'left',
      id,
      label,
      className,
      width,
      padding,
      background,
      'data-testid': dataTestId,
      flexContainer,
      transparent = false,
      color = 'text-main',
      ...props
    },
    ref
  ) => (
    <div
      className={clsx('w-full', flexContainer ?? 'flex', 'group/text-input')}
    >
      {label &&
        match(typeof label)
          .with('string', () => (
            <label htmlFor={id} className="block w-fit">
              <span className="text-main-500">{label}</span>
            </label>
          ))
          .otherwise(() => (
            <div
              className={clsx(
                'flex items-center',
                props.height ?? 'h-f2',
                'group-hover/text-input:bg-negative',
                'group-focus-within/text-input:bg-negative'
              )}
            >
              {label}
            </div>
          ))}
      <div className={clsx(width)}>
        <input
          data-testid={dataTestId}
          type="text"
          ref={ref}
          id={id}
          className={clsx(
            width,
            transparent
              ? [
                  'bg-transparent',
                  'hover:bg-transparent',
                  'group-hover/text-input:bg-transparent',
                  'focus:bg-transparent',
                ]
              : [
                  'bg-main-200',
                  'hover:bg-negative',
                  'group-hover/text-input:bg-negative',
                  'focus:bg-negative',
                ],
            !border && ['border-none', 'focus:border-none'],
            border && [
              'border',
              'focus:border-canvas',
              'border-canvas',
              'focus:ring-transparent',
              'active:ring-transparent',
              'active:outline-none',
            ],
            hasError && ['border-orange', 'focus:border-orange'],
            ['outline-none', 'focus:outline-none', 'active:outline-none'],
            ['focus:ring-transparent', 'active:ring-transparent'],
            getTextSize(size),
            [color, `placeholder:text-main-500`],
            padding,
            'min-w-0',
            // eslint-disable-next-line no-nested-ternary
            align === 'left'
              ? 'text-left'
              : align === 'center'
              ? 'text-center'
              : 'text-right',
            className,
            background
          )}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </div>
    </div>
  )
);
