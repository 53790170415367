import React from 'react';

export const GlobalApiAuthenticationFailed = () => (
  <div className="space-y-f2 text-s20">
    <span>Failed to authenticate user</span>
    <p>This is most likely our fault.</p>
  </div>
);

export const PodApiAuthenticationFailed = () => (
  <div className="space-y-f2 text-s20">
    <span>Failed to authenticate workspace</span>
    <p>
      This is most likely our fault - in the meantime, you can try switching to
      other workspaces.
    </p>
  </div>
);
