import type { IInitConfig } from 'flagsmith/types';
import { TopLevelLoader } from 'components/TopLevelLoader';
import flagsmith from 'flagsmith';
import { type FlagsmithContextType, FlagsmithProvider } from 'flagsmith/react';
import { localFlagValues } from 'localFlagValues';
import React, { useCallback, useState } from 'react';
import { FragmentEnv } from 'utils/env';

const shouldMockFlags =
  (window.Cypress && !localStorage.getItem('cypress:real_flagsmith')) ||
  FragmentEnv.flagsmith.shouldMock;

const baseOptions: IInitConfig = {
  environmentID: FragmentEnv.flagsmith.apiKey,
  api: FragmentEnv.flagsmith.apiUrl, // TODO - migrate flagsmith to the Edge API https://docs.flagsmith.com/advanced-use/edge-api
  // Don't fetch flag values locally and use a custom override instead.
  cacheFlags: true,
  preventFetch: shouldMockFlags,
  defaultFlags: shouldMockFlags
    ? localFlagValues
    : FragmentEnv.flagsmith.defaultFlags,
};

if (shouldMockFlags) {
  // eslint-disable-next-line no-console
  console.info(
    'Feature gate mock values (edit localFlagValues.ts to change): ',
    localFlagValues
  );
}
if (import.meta.env.VITEST_POOL_ID !== undefined && !shouldMockFlags) {
  console.error(
    'Feature gate provider should be mocked in tests. This is likely due to you overriding `dashboard/.env`. Please rerun `yarn gen-env`.'
  );
}

type Props = {
  children: FlagsmithContextType['children'];
};

export const FeatureGateProvider = ({ children }: Props) => {
  const [loadingState, setLoadingState] = useState<
    (typeof flagsmith)['loadingState']
  >(flagsmith.loadingState);

  const [error, setError] = useState<Error>();
  // Flagsmith provides a way for us to listen on loading events, but it's not
  // reliable at this point. We care about two things here:
  // 1) Have we fully loaded the flags from the server?
  // 2) Was there an error while loading the flags?
  // We do not get a change event on errors, so we have to listen to these separately.
  const onChange: NonNullable<IInitConfig['onChange']> = useCallback(
    (_previousFlags, _params, ls) => {
      // Flagsmith does some bad things and calls this in the render lifecycle
      // This is an error in react, so we need to defer this to the next tick.
      setTimeout(() => {
        setLoadingState(ls);
        setError(ls.error ?? undefined);
      }, 0);
    },
    [setLoadingState]
  );
  const onError: NonNullable<IInitConfig['onError']> = useCallback(
    (err) => {
      setError(err);
    },
    [setError]
  );
  const options = shouldMockFlags
    ? baseOptions
    : {
        ...baseOptions,
        onChange,
        onError,
      };

  return (
    <FlagsmithProvider {...{ options, flagsmith }}>
      {/* There are two cases where we want to display the real children */}
      {/* 1) If we had an error, then flagsmith is down and we're serving defaults. */}
      {/*    We do not get an onChange event for this */}
      {/* 2) If we are fully loaded */}
      {shouldMockFlags || error || loadingState?.isLoading === false ? (
        children
      ) : (
        <TopLevelLoader />
      )}
    </FlagsmithProvider>
  );
};
