import type {
  SessionUserinfoQuery as GlobalApiSessionUserinfoQuery,
  SessionUserinfoQuery,
  SessionUserinfoQueryVariables as GlobalApiSessionUserinfoQueryVariables,
} from 'hooks/globalApi';
import type { PropsWithChildren } from 'react';
import { useFlagsmith } from 'flagsmith/react';
import { H } from 'highlight.run';
import {
  SessionUserinfoDocument as GlobalApiSessionUserinfoDocument,
  useSessionUserinfoQuery as useGlobalApiSessionUserinfoQuery,
} from 'hooks/globalApi';
import { useGlobalApiContext } from 'hooks/useGlobalApiContext';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Provider as UrqlProvider, useClient } from 'urql';
import { useGlobalApiAccessToken } from 'wrappers/GlobalApiAccessTokenProvider';
import { defaultClient } from 'wrappers/urqlProvider/UrqlProvider';

export type Userinfo = NonNullable<SessionUserinfoQuery['userinfo']>;
export type UserInfoContextValue = (
  | {
      data: Userinfo;
      type: 'user_found';
    }
  | {
      data: null;
      type: 'user_not_found' | 'not_logged_in' | 'server_error';
    }
) & { refreshUserState: () => Promise<void> };

export type Workspace = NonNullable<Userinfo['workspaces']>[0];

export const UserInfoContext = createContext<UserInfoContextValue>({
  type: 'not_logged_in',
  data: null,
  refreshUserState: async () => {},
});
const { Provider } = UserInfoContext;

const userInfoVariables = {} as const;

/**
 * This hook is a workaround for the fact that urql.useQuery's returned executeQuery function
 * doesn't trigger suspense and doesn't return a promise.
 * There are a few cases (such as workspace renaming, workspace creation) where we want to
 * mutate the user/their workspaces, and block until the operation is complete.
 *
 * We return fetchUserInfo as a () => Promise<void> so that UserInfoContext consumers
 * can await on refreshing the user.
 *
 * We still call the useSessionUserinfoQuery from urql in order to initially trigger suspense,
 * since we're managing our own state.
 */

const useSessionUserInfo = () => {
  const { context } = useGlobalApiContext();
  const [response] = useGlobalApiSessionUserinfoQuery({
    context,
    pause: !context.token,
    requestPolicy: 'cache-first',
  });
  const { data: queryData, error: queryError } = response;
  const [queryResponse, setQueryResponse] = useState<
    Pick<typeof response, 'data' | 'error'>
  >({
    data: queryData,
    error: queryError,
  });
  useEffect(() => {
    setQueryResponse({ data: queryData, error: queryError });
  }, [queryError, queryData]);

  const client = useClient();
  const fetchUserInfo = useCallback(async () => {
    const { data, error } = await client
      .query<
        GlobalApiSessionUserinfoQuery,
        GlobalApiSessionUserinfoQueryVariables
      >(GlobalApiSessionUserinfoDocument, userInfoVariables, {
        ...context,
        pause: !context.token,
        requestPolicy: 'network-only',
      })
      .toPromise();
    setQueryResponse({ data, error });
  }, [client, context]);
  return [queryResponse, fetchUserInfo] as const;
};

export const InternalUserInfoProvider = ({ children }: PropsWithChildren) => {
  const ctx = useGlobalApiAccessToken();
  const [{ data, error }, refreshUserState] = useSessionUserInfo();
  const userInfoData: UserInfoContextValue = useMemo(() => {
    if (data?.userinfo) {
      return {
        type: 'user_found',
        data: data.userinfo,
        refreshUserState,
      };
    }
    if (error?.message) {
      return {
        type: 'server_error',
        data: null,
        refreshUserState,
      };
    }
    return {
      type: 'user_not_found',
      data: null,
      refreshUserState,
    };
  }, [error?.message, data?.userinfo, refreshUserState]);

  // cannot destructure here because flagsmith does not bind this
  const flagsmith = useFlagsmith();
  useEffect(() => {
    if (data?.userinfo?.id) {
      flagsmith.identify(data.userinfo.id);
    }
  }, [flagsmith, data?.userinfo?.id]);

  useEffect(() => {
    if (data?.userinfo) {
      H.identify(data.userinfo.email ?? data.userinfo.id, {
        id: data.userinfo.id,
      });
    }
  }, [data?.userinfo]);

  // note - this is still necessary even with global api
  return ctx?.globalApiAccessToken ? (
    <Provider value={userInfoData}>{children}</Provider>
  ) : (
    // TODO: Update linter to make this acceptable or be more strict about children type
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

export const UserInfoProvider = ({ children }: PropsWithChildren) => {
  const originalClient = useClient();
  return (
    // The URQL client from the context changes whenever you switch workspaces.
    // This invalidates caches, and causes duplicate network requests to be made.
    // so, we wrap the UserInfoProvider with a stable client, and then wrap the children
    // with the original client.
    <UrqlProvider value={defaultClient}>
      <InternalUserInfoProvider>
        <UrqlProvider value={originalClient}>{children}</UrqlProvider>
      </InternalUserInfoProvider>
    </UrqlProvider>
  );
};

export const useUserInfo = () => useContext(UserInfoContext);
