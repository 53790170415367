import { z } from 'zod';

export const FragmentDashboardEnvSchema = z.object({
  stage: z.string(),
  fragment: z.object({
    globalApi: z.string(),
    rootUrl: z.string(),
    contactUrl: z.string(),
  }),
  auth0: z.object({
    clientId: z.string(),
    globalApiAudience: z.string(),
    domain: z.string(),
  }),
  increase: z.object({
    clientId: z.string(),
  }),
  stripe: z.object({
    clientId: z.object({
      testmode: z.string(),
      livemode: z.string(),
    }),
    redirectUri: z.string(),
  }),
  flagsmith: z.object({
    apiKey: z.string(),
    apiUrl: z.string(),
    shouldMock: z.boolean(),
    defaultFlags: z.optional(
      z.record(
        z.string(),
        z.object({
          enabled: z.boolean(),
          value: z.optional(z.union([z.string(), z.number(), z.boolean()])),
        })
      )
    ),
  }),
  highlight: z.object({
    projectId: z.string().optional(),
    domain: z.string(),
  }),
});

export type FragmentDashboardEnv = z.infer<typeof FragmentDashboardEnvSchema>;
