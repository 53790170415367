import type { ReactNode } from 'react';
import clsx from 'clsx';

import type { TextSizeType } from '../utils';
import type { Props as CellProps } from './Cell';

type Formatter<K> = (value: K) => ReactNode | null;

export type ColumnDefinition<T> = CellProps & { title: string } & (
    | {
        key: keyof T;
      }
    | { format: Formatter<T> }
  );

// We want to be as flexible as possible, but every row at least needs an ID
export type BaseRowType = {
  id: unknown;
  testId?: string;
};

export type BaseTableProps<T extends BaseRowType> = {
  data: readonly T[];
  schema: readonly ColumnDefinition<T>[];
  emptyValue?: Record<ColumnDefinition<T>['title'], ReactNode>;
  selected?: Pick<T, 'id'>;
  hasNextPage?: false;
  'data-testid'?: string;
  textSize?: TextSizeType;
};

export const gridClasses = (textSize: TextSizeType = 'fnormal') =>
  clsx(
    'grid',
    'grid-flow-col', // dynamic number of columns
    'auto-cols-fr', // column sizes are a fixed ratio
    textSize === 'fnormal' ? 'px-f2' : 'px-f0',
    'gap-x-[1ch]'
  );
