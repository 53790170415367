import { Routes } from 'components/routing/Routes';
import { TopLevelLoader } from 'components/TopLevelLoader';
import React, { Suspense } from 'react';
import { AuthProvider } from 'wrappers/AuthProvider';
import { FeatureGateProvider } from 'wrappers/FeatureGateProvider';
import { GlobalApiAccessTokenProvider } from 'wrappers/GlobalApiAccessTokenProvider';
import { GlobalErrorBoundary } from 'wrappers/GlobalErrorBoundary';
import { IdleTimerContainer } from 'wrappers/IdleTimerContainer/IdleTimerContainer';
import { PodApiAccessTokenProvider } from 'wrappers/PodApiAccessTokenProvider';
import { UrqlProvider } from 'wrappers/urqlProvider/UrqlProvider';
import { UserInfoProvider } from 'wrappers/UserInfoProvider';
import { WorkspaceProvider } from 'wrappers/WorkspaceProvider';

import { ColorModeProvider } from '@fragment/ui/src/components/ColorMode/ColorModeProvider';

import { ToastProvider } from './wrappers/ToastProvider';
import { HelpModeProvider } from 'wrappers/HelpModeProvider';

export const App = () => (
  <FeatureGateProvider>
    <GlobalErrorBoundary>
      <AuthProvider>
        <ColorModeProvider>
          <Suspense fallback={<TopLevelLoader />}>
            <GlobalApiAccessTokenProvider>
              <UrqlProvider>
                <UserInfoProvider>
                  <WorkspaceProvider>
                    <PodApiAccessTokenProvider>
                      <IdleTimerContainer>
                        <Suspense fallback={<TopLevelLoader />}>
                          <ToastProvider>
                            <HelpModeProvider>
                              <Routes />
                            </HelpModeProvider>
                          </ToastProvider>
                        </Suspense>
                      </IdleTimerContainer>
                    </PodApiAccessTokenProvider>
                  </WorkspaceProvider>
                </UserInfoProvider>
              </UrqlProvider>
            </GlobalApiAccessTokenProvider>
          </Suspense>
        </ColorModeProvider>
      </AuthProvider>
    </GlobalErrorBoundary>
  </FeatureGateProvider>
);
