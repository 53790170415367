import type { ReactNode } from 'react';
import clsx from 'clsx';
import React from 'react';

import type { TextSizeType } from '../../utils';
import { Button } from '../../Button/BaseButton/Button';
import { Cell } from '../Cell';
import { type ColumnDefinition, gridClasses } from '../helpers';

const formatKeyAsText = <T,>(data: T) => <span>{String(data)}</span>;

const format = <T,>(value: T, definition: ColumnDefinition<T>) => {
  if ('format' in definition) {
    return definition.format(value);
  }
  return formatKeyAsText(value[definition.key]);
};

type RowProps<T> = {
  value: T;
  schema: readonly ColumnDefinition<T>[];
  selected?: boolean;
  index: number;
  textSize?: TextSizeType;
  disableHover?: boolean;
};

export const Row = <T,>({
  value,
  schema,
  selected,
  index,
  textSize = 'fnormal',
  disableHover = false,
}: RowProps<T>) => (
  <div
    className={clsx(
      gridClasses(textSize),
      selected ? 'bg-negative' : 'bg-transparent',
      !disableHover &&
        'group-hover:bg-negative group-focus:bg-negative cursor-pointer'
    )}
    role="row"
    aria-rowindex={index}
  >
    {schema.map((def) => (
      <Cell
        textSize={textSize}
        key={def.title}
        justify={def.justify}
        colspan={def.colspan}
      >
        {format(value, def)}
      </Cell>
    ))}
  </div>
);
Row.displayName = 'NavTable.Row';

type EmptyTableRowProps<T> = {
  value: Record<ColumnDefinition<T>['title'], ReactNode>;
  schema: readonly ColumnDefinition<T>[];
  textSize?: TextSizeType;
};

export const EmptyTableRow = <T,>({
  value,
  schema,
  textSize = 'fnormal',
}: EmptyTableRowProps<T>) => (
  <div className={clsx(gridClasses(textSize), 'bg-transparent')} role="row">
    {schema.map(({ title, justify, colspan }) => (
      <Cell textSize={textSize} key={title} justify={justify} colspan={colspan}>
        {value[title]}
      </Cell>
    ))}
  </div>
);

export type LoadMoreRowProps = {
  isLoading?: boolean;
  loadMore: VoidFunction;
  textSize?: TextSizeType;
};

export const LoadMoreRow = ({
  isLoading,
  loadMore,
  textSize = 'fnormal',
}: LoadMoreRowProps) => (
  <div className={clsx(gridClasses(textSize), 'hover:bg-negative')} role="row">
    <Cell justify="left" textSize={textSize}>
      <Button
        textAlign="text-left"
        type="button"
        disabled={isLoading}
        onClick={loadMore}
        color="text-main-500"
      >
        {isLoading ? 'Loading...' : 'Load More'}
      </Button>
    </Cell>
  </div>
);
